// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// SweetAlert
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { TaskAPIs } from '../../../../apis'

const defaultParams = {
	q: "",
	page: 1,
	isParamChanged: true
}
const MySwal = withReactContent(Swal)

export const getData = createAsyncThunk('appInvoice/getData', async (params) => {
  const response = await TaskAPIs.webAppList({
    ...params,
    search: params.q,
    parameter: {
      taskListDate:params.taskListDate,
      status:params.status,
      siteID:params.siteID
    }
  })
  const timeZoneId = response.data.timeZoneId || ''
  const updatedList = response.data.list.map((item) => ({
    ...item,
    timeZoneId
  }))
  return {
    params,
    data: updatedList,
    allData: updatedList,
    totalPages: response.data.records
  }
})
 
export const clearData = createAsyncThunk('appInvoice/clearData', () => {
  return {
    data: [],
    allData: [],
    totalPages: 0
  }
})

export const importTemplateCheckList = createAsyncThunk('appInvoice/importTemplateCheckList', async (checklists, { dispatch, getState }) => {
  await dispatch(getData(getState().appInvoice.params))
  return {
    data: [],
    allData: [],
    totalPages: 0,
    checklists
  }
})

export const  simpleList = createAsyncThunk('appInvoice/simpleList', async (params) => {
  const response =  await TaskAPIs.simpleList({
       ...params,
		   search: params.q
    })
    return {
      taskList: response.data.list
    }
})

export const setData = createAsyncThunk('appInvoice/setData', async params => {
  return {
    taskListDate: params.taskListDate
  }
})

export const addTask = createAsyncThunk('appInvoice/addTask', async (TaskData, { dispatch, getState }) => {
  await TaskAPIs.createNewOne({ body: TaskData })
  await dispatch(getData({
    ...getState().task.params,
    isParamChanged: true
  }))
  await dispatch(getDepartmentData(defaultParams))
})

export const setModalData = createAsyncThunk('appInvoice/setModalData', async params => {
  return {
    modalData: {
      isModalOpen: params.isModalOpen,
      id: params._id,
      individualTask: params.individualTask
    }
  }
})

export const setModalDataViewTask = createAsyncThunk('appInvoice/setModalDataViewTask', async params => {
  return {
    modalDataViewTask: {
      isModalOpenView: params.isModalOpenView,
      masterTaskID: params.masterTaskID,
      individualTaskID: params.individualTaskID,
      taskListDate: params.taskListDate
    }
  }
})

export const deleteTask = createAsyncThunk('appInvoice/deleteTask', async (_id, { dispatch }) => {
  await TaskAPIs.deleteOne({ taskID: _id })
	await dispatch(getData(defaultParams))
  await dispatch(setModalData({_id : '', individualTask : {}, isModalOpen: false}))
  await dispatch(simpleList({}))
	return _id
})

export const deleteIndividualTask = createAsyncThunk('appInvoice/deleteIndividualTask', async (TaskData, { dispatch, getState }) => {
  await TaskAPIs.deleteIndividualTask({ body: TaskData })
	await dispatch(getData({
    ...getState().task.params,
    isParamChanged: true
  }))
  await dispatch(setModalData({_id : '', individualTask : {}, isModalOpen: false}))
})

export const getOneData = createAsyncThunk('appInvoice/getOneData', async params => {
	try {
		const response = await TaskAPIs.findOne(params)

		return {
			taskDetail: response.data
		}
	} catch (error) {
		showErrorToast(error)
	}
})

export const editTask = createAsyncThunk('appInvoice/editTask', async (TaskData, { dispatch, getState }) => { 
  await TaskAPIs.updateOne({ body: TaskData })
  await dispatch(getData({
    ...getState().task.params,
    isParamChanged: true
  }))
})

export const  commentList = createAsyncThunk('appInvoice/commentList', async (params) => {
  const response =  await TaskAPIs.commentList({
       ...params
    })
    return {
      commentList: response.data.results

    }
})

export const viewTask = createAsyncThunk('appInvoice/view', async (params, {dispatch}) => {
  try {
    const response = await TaskAPIs.view(params)
    dispatch(commentList({masterTaskID: response.data.masterTaskID, individualTaskID: response.data._id }))
    return {
      viewTaskDetail: response.data
    }
  } catch (error) {
    showErrorToast(error)
  }
})

export const addComment =  createAsyncThunk('appInvoice/addTask', async (TaskData, { dispatch }) => {
  await TaskAPIs.addCommnet({ body: TaskData })
  await dispatch(commentList({masterTaskID: TaskData.get("masterTaskID"), individualTaskID: TaskData.get("individualTaskID")}))
})


export const taskStatusUpdate = createAsyncThunk('appInvoice/taskStatusUpdate', async (StatusData, { dispatch }) => {
  await TaskAPIs.taskStatusUpdate({ body: {masterTaskID: StatusData.masterTaskID, individualTaskID : StatusData.individualTaskID} })
  await dispatch(viewTask({masterTaskID: StatusData.masterTaskID, individualTaskID : StatusData.individualTaskID}))
  await dispatch(getData({...defaultParams, q:StatusData.q, /* page:StatusData.currentPage ,*/ taskListDate:StatusData.taskDate, status:StatusData.statusValue, siteID:StatusData.siteID, sort: StatusData.sort, sortColumn:StatusData.sortColumn}))
})

export const CheckListStatusUpdate = createAsyncThunk('appInvoice/CheckListStatusUpdate', async (CheckListStatusData, { dispatch }) => {
  const statusUpdatePayload = JSON.parse(JSON.stringify(CheckListStatusData))
  delete statusUpdatePayload.sort
  delete statusUpdatePayload.sortColumn
  delete statusUpdatePayload.statusValue
  delete statusUpdatePayload.siteID
  delete statusUpdatePayload.q
  delete statusUpdatePayload.currentPage
  
  await TaskAPIs.checkItemStatusUpdate({ body: statusUpdatePayload })
  await dispatch(viewTask({masterTaskID: CheckListStatusData.masterTaskID, individualTaskID : CheckListStatusData.individualTaskID}))
  await dispatch(getData({...defaultParams, q:CheckListStatusData.q, /* page:CheckListStatusData.currentPage ,*/ taskListDate:CheckListStatusData.taskDate, status:CheckListStatusData.statusValue, siteID:CheckListStatusData.siteID, sort: CheckListStatusData.sort, sortColumn:CheckListStatusData.sortColumn}))
})

export const taskReset = createAsyncThunk('appInvoice/taskReset', () => {
  return {
    viewTaskDetail: {}
  }
})


export const appInvoiceSlice = createSlice({
  name: 'appInvoice',
  initialState: {
    data: [],
    loading: false,
    total: 1,
    params: {},
    allData: [],
    checklists: [],
    modalData: {
      isModelOpen :false,
      id: ''
    },
    modalDataViewTask: {
      isModalOpenView :false,
      masterTaskID: '',
      individualTaskID: '',
      taskListDate:''
    },
    viewTaskDetail: {},
    taskListDate: ''
  },

  reducers: {
    setLoading: (state, action) => {
      state.loading =  action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(getData.pending, (state) => {
      state.loading = true 
      state.error = null 
    })
    .addCase(getData.fulfilled, (state, action) => {
      if (action.payload.params.isParamChanged) {
        state.data = action.payload.data
    } else {
        state.data = [...state.data, ...action.payload.data]
    }
     state.loading = false
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
      state.params = action.payload.params
      state.checklists = action.payload.checklists
    })
    .addCase(getData.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    .addCase(setData.fulfilled, (state, action) => {
      state.taskListDate = action.payload.taskListDate
    })
    .addCase(getOneData.fulfilled, (state, action) => {
      state.taskDetail = action.payload.taskDetail
    })
    .addCase(setModalData.fulfilled, (state, action) => {
      state.modalData = action.payload.modalData
    })
    .addCase(setModalDataViewTask.fulfilled, (state, action) => {
      state.modalDataViewTask = action.payload.modalDataViewTask
    })
    .addCase(viewTask.fulfilled, (state, action) => {
      state.viewTaskDetail = action.payload.viewTaskDetail
    })
    .addCase(taskReset.fulfilled, (state, action) => {
      state.viewTaskDetail = action.payload.viewTaskDetail
    })
    .addCase(commentList.fulfilled, (state, action) => {
      state.commentList = action.payload.commentList
    }) 
    .addCase(simpleList.fulfilled, (state, action) => {
      state.taskList = action.payload.taskList
    })   
  }
})

export const {setLoading} = appInvoiceSlice.actions
export default appInvoiceSlice.reducer
