import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { SiteAPIs } from '../../../../apis'

const showErrorToast = (error) => {
	return toast.error(
		error.message ||
		error?.response?.data?.message ||
		error.error.message ||
		error.response.message
	)
}

export const getAllSiteScoreList = createAsyncThunk('appSiteReport/getAllSiteScoreList', async params => {
  try {
      const response = await SiteAPIs.allSiteScoreList({
          ...params,
          parameter: {
            startDate: params.startDate,
            endDate: params.endDate
          }
        })
        return {
          params,
          allData: response.data.list,
          taskStatusData: response.data.status,
          totalPages: response.data.records
        }
  } catch (error) {
		showErrorToast(error)
	}
})


export const getSiteScoreData = createAsyncThunk('appSiteReport/getSiteScoreData', async params => {
  try {
    const response = await SiteAPIs.siteReportScoreList({
        ...params,
        parameter: {
          startDate: params.startDate,
          endDate: params.endDate
        }
      })
      return {
        params,
        siteTaskData: response.data.list
      }
  } catch (error) {
    showErrorToast(error)
  }
})

export const appInvoiceSlice = createSlice({
    name: 'appSiteReport',
    initialState: {
        allData: [],
        taskStatusData: [],
        total: 1,
        params: {},
        allData: [],
        siteTaskData: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getAllSiteScoreList.fulfilled, (state, action) => {
            state.allData = action.payload.allData
            state.taskStatusData = action.payload.taskStatusData
            state.total = action.payload.totalPages
            state.params = action.payload.params
        })
        .addCase(getSiteScoreData.fulfilled, (state, action) => {
            state.siteTaskData = action.payload.siteTaskData
        })
    }
})
export default appInvoiceSlice.reducer
