import endPoint from "./_endPoint"
import { axiosCallAdvanced } from "./_main"

const rootPath = endPoint.siteRoute.root

export const webAppList = async (parameters) => {
	return (
		await axiosCallAdvanced({
			...parameters,
			baseURL: rootPath,
			path: endPoint.siteRoute.webAppList
		})
	)?.data
}

export const createNewOne = async (parameters) => {
	return (
		await axiosCallAdvanced({
			...parameters,
			baseURL: rootPath,
			path: endPoint.siteRoute.createNewOne,
			method: endPoint.methodType.post
		})
	)?.data
}

export const updateOne = async (parameters) => {
	return (
		await axiosCallAdvanced({
			...parameters,
			baseURL: rootPath,
			path: endPoint.siteRoute.updateOne,
			method: endPoint.methodType.put
		})
	)?.data
}

export const deleteOne = async (parameters) => {
	return (
		await axiosCallAdvanced({
			...parameters,
			baseURL: rootPath,
			path: endPoint.siteRoute.deleteOne,
			method: endPoint.methodType.patch
		})
	)?.data
}

export const simpleList = async (parameters) => {
	return (
		await axiosCallAdvanced({
			...parameters,
			baseURL: rootPath,
			path: endPoint.siteRoute.simpleList
		})
	)?.data
}

export const findOne = async (parameters) => {
	const path = endPoint.siteRoute.findOne.replace(':id', parameters.siteID)

	return (
		await axiosCallAdvanced({
			...parameters,
			baseURL: rootPath,
			path
		})
	)?.data
}

export const timeSlot = async (parameters) => {
	const path = endPoint.siteRoute.timeslot.replace(':id', parameters.siteID)

	return (
		await axiosCallAdvanced({
			...parameters,
			baseURL: rootPath,
			path
		})
	)?.data
}

export const location = async (parameters) => {
	return (
		await axiosCallAdvanced({
			...parameters,
			baseURL: rootPath,
			path: endPoint.siteRoute.location,
			method: endPoint.methodType.post,
			body: parameters.body || {}
		})
	)?.data
}

export const fetchTimezoneData = async (parameters) => {
	return (
		await axiosCallAdvanced({
			...parameters,
			baseURL: rootPath,
			path: endPoint.siteRoute.fetchTimezoneData,
			method: endPoint.methodType.post,
			body: parameters.body || {}
		})
	)?.data
}

export const fetchCountry = async (parameters) => {
	return (
		await axiosCallAdvanced({
			...parameters,
			baseURL: rootPath,
			path: endPoint.siteRoute.fetchCountry,
			method: endPoint.methodType.post,
			body: parameters.body || {}
		})
	)?.data
}

export const allSiteScoreList = async (parameters) => {
	return (
		await axiosCallAdvanced({
			...parameters,
			baseURL: rootPath,
			path: endPoint.siteRoute.allSiteScoreList
		})
	)?.data
}

export const siteReportScoreList = async (parameters) => {
	return (
		await axiosCallAdvanced({
			...parameters,
			baseURL: rootPath,
			path: endPoint.siteRoute.siteReportScoreList
		})
	)?.data
}

export const closingTimeList = async (parameters) => {
	return (
		await axiosCallAdvanced({
			...parameters,
			baseURL: rootPath,
			path: endPoint.siteRoute.closingTimeList,
			method: endPoint.methodType.post
			// body: parameters.body || {}
		})
	)?.data
}

export default {
	webAppList,
	createNewOne,
	updateOne,
	deleteOne,
	simpleList,
	findOne,
	timeSlot,
	location,
	fetchCountry,
	fetchTimezoneData,
	closingTimeList,
	allSiteScoreList,
	siteReportScoreList
}
