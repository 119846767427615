import endPoint from "./_endPoint"
import { axiosCallAdvanced } from "./_main"
const rootPath = endPoint.taskRoute.root
const commentRootpath = endPoint.commentTask.root
const individualTask = endPoint.individualTask.root

export const createNewOne = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.taskRoute.createNewOne,
            method: endPoint.methodType.post
        })
    )?.data
}

export const simpleList = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.taskRoute.simpleList
        })
    )?.data

   
}

export const deleteOne = async (parameters) => {
    const path = endPoint.taskRoute.deleteOne.replace(':id', parameters.taskID)
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path,
            method: endPoint.methodType.delete
        })
    )?.data
}

export const deleteIndividualTask = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.taskRoute.deleteIndividualTask,
            method: endPoint.methodType.delete
        })
    )?.data
}

export const findOne = async (parameters) => {
    const path = endPoint.taskRoute.findOne.replace(':id', parameters.taskID)

    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path
        })
    )?.data
}

export const updateOne = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.taskRoute.updateOne,
            method: endPoint.methodType.put
        })
    )?.data
}

export const webAppList = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.taskRoute.webAppList
        })
    )?.data
}

export const view = async (parameters) => {
    const path = `${endPoint.taskRoute.viewTask}?masterTaskID=${parameters.masterTaskID}&individualTaskID=${parameters.individualTaskID}`

    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path
        })
    )?.data
}

export const addCommnet = async (parameters) => {
    console.log(parameters, 'parameters')
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: commentRootpath,
            path: endPoint.commentTask.addComments,
            method: endPoint.methodType.post
        })
    )?.data
}

export const commentList = async (parameters) => {
    const path = `${endPoint.commentTask.commentList}?masterTaskID=${parameters.masterTaskID}&individualTaskID=${parameters.individualTaskID}`

    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: commentRootpath,  
            path
        })
    )?.data

   
}

export const checkItemStatusUpdate = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: individualTask,
            path: endPoint.individualTask.checkListStatus,
            method: endPoint.methodType.put
        })
    )?.data
}

export const taskStatusUpdate = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: individualTask,
            path: endPoint.individualTask.taskStatus,
            method: endPoint.methodType.put
        })
    )?.data
}


export default {
    createNewOne,
    simpleList,
    deleteOne,
    findOne,
    updateOne,
    webAppList,
    view,
    addCommnet,
    commentList,
    checkItemStatusUpdate,
    taskStatusUpdate,
    deleteIndividualTask
}
